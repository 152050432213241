import 'script-loader!../lib/prototype/prototype';
import 'script-loader!../../../../../js/prototype/validation';

//import 'script-loader!../../../../../js/lib/ccard';

import 'script-loader!../../../../../js/scriptaculous/effects';
// import 'script-loader!../../../../../js/scriptaculous/dragdrop';
// import 'script-loader!../../../../../js/scriptaculous/controls';
// import 'script-loader!../../../../../js/scriptaculous/slider';

import 'script-loader!../../../../../js/varien/js';
import 'script-loader!../../../../../js/varien/form';
// import 'script-loader!../../../../../js/varien/menu';

import 'script-loader!../../../../../js/mage/translate';
import 'script-loader!../../../../../js/mage/cookies';
import 'script-loader!../../../../../js/mage/captcha';